import React from "react";
import image from "../assets/reunion.jpg";

const APropos = () => {
  return (
    <section className=" py-20 mt-8 md:mt-16 mx-6 md:mx-0">
      <div className="hidden md:block absolute w-64 h-64  bg-gradient-to-tr from-[#527FF6] from-[-20%] via-[#E16DD9] via-40% to-[#FFCC70] to-[130%] rounded-full blur-2xl -z-20 right-0 mt-96"></div>

      <div className="container mx-auto flex flex-col-reverse md:flex-row items-center gap-20">
        <div className=" flex-1 md:block hidden h-[600px]">
          <img
            src={image}
            alt="Équipe qui travaille"
            className=" object-cover rounded-3xl h-full"
          />
        </div>
        <div className="flex-1">
          <span
            id="a-propos"
            className="font-poppins text-gray-600 mb-4 block uppercase"
          >
            A propos
          </span>
          <h2 className="text-kantumruy font-semibold text-3xl md:text-6xl text-gray-900  mb-6 md:mb-12">
            Un site professionnel{" "}
            <span className="hidden md:visible md:block">et clé-en-main.</span>
          </h2>
          <p className="font-poppins text-gray-600 md:text-gray-500 text-lg mb-4 leading-relaxed">
            Besoin d'une présence en ligne ? Nous créons pour vous un{" "}
            <strong className="font-normal text-gray-900">
              site vitrine professionnel , prêt à l'emploi, dès 49€/mois
            </strong>
            . Laissez-nous gérer le web, pour que vous puissiez vous concentrer
            sur votre métier.
          </p>
          <p className="font-poppins text-gray-600 md:text-gray-500 text-lg mb-8 md:mb-12 leading-relaxed">
            {" "}
            Oubliez les vendeurs de tapis qui vous vendent{" "}
            <strong className="font-normal text-gray-900">
              des sites templatisés hors de prix
            </strong>
            , et passez par nos sites{" "}
            <strong className="font-normal text-gray-900">
              codés à la main
            </strong>{" "}
            par nos experts, garantissant un SEO et une qualité supérieure.
          </p>
          <a href="#contact">
            <button className="group inline-flex items-center hover:shadow-lg hover:shadow-blue-500/50 duration-300 font-semibold ease-in-out bg-gradient-to-t from-blue-500 via-blue-500 via-60% to-blue-400 text-white py-3 px-6 rounded-lg text-lg">
              Commencer
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default APropos;
