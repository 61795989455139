import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Correct import
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import FacebookPixel from "./tracking/Facebook";

export default function App() {
  return (
    <Router>
      {/* Use BrowserRouter here */}
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        <Route path="/portfolio" element={<Portfolio />} />{" "}
        {/* Use Route instead of Routes */}
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
}
