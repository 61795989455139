import React, { useEffect, useState } from "react";
import { Check, CircleHelp, Clock, Minus } from "lucide-react";

import darkmode from "../assets/darkmode.png";

import chrome from "../assets/browsers/chrome.png";
import edge from "../assets/browsers/edge.png";
import opera from "../assets/browsers/opera.png";
import brave from "../assets/browsers/brave.png";
import safari from "../assets/browsers/safari.png";
import firefox from "../assets/browsers/firefox.png";

import windows from "../assets/oss/windows.png";
import macos from "../assets/oss/macos.png";
import linux from "../assets/oss/linux.png";
import ios from "../assets/oss/ios.png";
import android from "../assets/oss/android.png";

import desktop from "../assets/devices/desktop.png";
import mobile from "../assets/devices/iphone.png";
import tablet from "../assets/devices/ipad.png";

// Helper function to parse and bold text wrapped with **
const parseBoldText = (text) => {
  const parts = text.split(/(\*\*.*?\*\*)/);
  return parts.map((part, index) =>
    part.startsWith("**") && part.endsWith("**") ? (
      <strong key={index}>{part.slice(2, -2)}</strong>
    ) : (
      part
    )
  );
};

// Pricing component that renders PricingCard components dynamically
const Pricing = () => {
  const [browser, setBrowser] = useState("Inconnu");
  const [os, setOS] = useState("Inconnu");
  const [device, setDevice] = useState("desktop");
  const [timeOnSite, setTimeOnSite] = useState(0);
  const [visits, setVisits] = useState(0);

  useEffect(() => {
    const detectBrowser = () => {
      const userAgent = navigator.userAgent;

      if (/Brave/i.test(navigator.userAgent)) {
        return "Brave";
      }

      if (userAgent.includes("Firefox")) {
        return "Firefox";
      }

      if (userAgent.includes("Edg/")) {
        return "Edge";
      }

      if (userAgent.includes("OPR/") || userAgent.includes("Opera")) {
        return "Opera";
      }

      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        return "Safari";
      }

      if (userAgent.includes("Chrome")) {
        return "Chrome";
      }

      return "Inconnu";
    };

    const detectOS = () => {
      const userAgent = navigator.userAgent;

      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
      }

      if (userAgent.includes("Win")) return "Windows";
      if (userAgent.includes("Mac")) return "macOS";
      if (userAgent.includes("Linux")) return "Linux";
      if (userAgent.includes("Android")) return "Android";

      return "Inconnu";
    };

    const detectDevice = () => {
      const userAgent = navigator.userAgent;

      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          userAgent
        )
      ) {
        return "mobile";
      }
      return "desktop";
    };

    // Récupérer le nombre de visites du localStorage
    const storedVisits = parseInt(localStorage.getItem("siteVisits")) || 0;
    setVisits(storedVisits + 1);
    localStorage.setItem("siteVisits", storedVisits + 1);

    // Timer pour le temps passé sur le site
    const startTime = Date.now();
    const timer = setInterval(() => {
      const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
      setTimeOnSite(elapsedSeconds);
    }, 1000);

    setBrowser(detectBrowser());
    setOS(detectOS());
    setDevice(detectDevice());

    return () => clearInterval(timer);
  }, []);

  // Fonction pour formater le temps (convertit les secondes en format lisible)
  const formatTime = (seconds) => {
    if (seconds < 60) return `${seconds}s`;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  // Calcule le nombre de places basées sur la date actuelle
  const getAvailableSeats = () => {
    const day = new Date().getDate(); // Récupère le jour du mois
    return (day % 4) + 6; // Modulo 4 donne un résultat de 0 à 3, on ajoute 6 pour obtenir 6 à 9
  };

  const availableSeats = getAvailableSeats();

  const getBrowserLogo = (browser) => {
    switch (browser.toLowerCase()) {
      case "chrome":
        return chrome;
      case "edge":
        return edge;
      case "opera":
        return opera;
      case "brave":
        return brave;
      case "safari":
        return safari;
      case "firefox":
        return firefox;
      default:
        return chrome;
    }
  };

  const getOSLogo = (os) => {
    switch (os.toLowerCase()) {
      case "windows":
        return windows;
      case "macos":
        return macos;
      case "linux":
        return linux;
      case "ios":
        return ios;
      case "android":
        return android;
      default:
        return windows;
    }
  };

  const getDeviceLogo = (device) => {
    switch (device.toLowerCase()) {
      case "mobile":
        return mobile;
      case "desktop":
        return desktop;
      case "tablet":
        return tablet;
      default:
        return desktop;
    }
  };

  // Ajout de la fonction pour gérer les codes promo
  const getStripeUrl = (baseUrl) => {
    const urlParams = new URLSearchParams(window.location.search);
    const refCode = urlParams.get("ref");
    return refCode
      ? `${baseUrl}?prefilled_promo_code=${refCode.toUpperCase()}`
      : baseUrl;
  };

  return (
    <section
      id="tarifs"
      className="text-center py-8 md:py-16 mt-12 md:mt-24 relative mx-auto"
    >
      <span className="font-poppins text-gray-600 block mb-4 uppercase">
        TARIFS
      </span>
      <h2 className="text-kantumruy font-semibold text-3xl md:text-6xl text-gray-900 mb-6 md:mb-12">
        Et combien ça coûte tout ça ?
      </h2>

      {/* Gradient Background */}
      <div className="absolute inset-x-0 -top-20 md:top-56 bottom-0 flex justify-center items-center">
        <div className="hidden md:block w-96 h-48 scale-[200%] -z-10 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full blur-3xl opacity-35"></div>
      </div>

      <div className="flex justify-center items-center md:flex-row flex-col gap-2 md:gap-0">
        <span className="flex flex-row gap-2 bg-emerald-100 text-emerald-600 border-emerald-600 text-sm md:font-medium me-2 px-2.5 py-0.5 rounded-full ">
          Satisfait ou remboursé <Check size={20} />
        </span>
        <span className="flex flex-row gap-2 items-center bg-amber-100 text-amber-600 border-amber-600 text-sm md:font-medium me-2 px-2.5 py-0.5 rounded-full ">
          {availableSeats}/10 places prises ce mois-ci <Clock size={18} />
        </span>
      </div>

      <div className="py-8 px-4 max-w-screen-xl relative z-10 mx-auto">
        <div className="flex flex-col md:flex-row md:justify-center md:space-x-6 xl:space-x-10 space-y-8 md:space-y-0 items-center md:items-stretch mx-auto">
          {/* Standard Card */}
          <div className="z-10 font-poppins p-12 flex flex-col max-w-lg text-center text-gray-900 bg-white rounded-3xl border">
            <h3 className="text-start text-xl text-gray-900 mb-4">Standart</h3>
            <ul className="mb-8 space-y-4 text-left">
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>{parseBoldText("Jusqu'à **5 pages**")}</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Design & Développement</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Aller-retours illimités</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Optimisé SEO</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Compatible mobile</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Hébergement sécurisé HTTPS</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Changements illimités</span>
              </li>{" "}
              <li className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <Minus className="text-gray-300" />
                  <span>Mode sombre/clair</span>
                </div>
                <div className="group relative">
                  <CircleHelp size={18} className="cursor-help text-gray-500" />
                  <div className="hidden group-hover:block absolute right-full md:left-full top-1/2 -translate-y-1/2 ml-2 w-64 bg-white p-3 rounded-lg border z-50">
                    <div className="text-sm text-gray-600">
                      <img
                        src={darkmode}
                        alt="Illustration du mode sombre et du mode clair"
                        className=""
                      />
                      <br />
                      Le mode sombre/clair permet à vos utilisateurs de choisir
                      l'apparence de votre site selon leurs préférences.
                    </div>
                    <div className="absolute -right-2 md:-left-2 top-1/2 -translate-y-1/2 w-4 h-4 rotate-45 bg-white border-r border-t md:border-b md:border-l md:border-r-0 md:border-t-0"></div>
                  </div>
                </div>
              </li>
              <li className="flex items-center space-x-3">
                <Minus className="text-gray-300" />
                <span>Réservations</span>
              </li>
              <li className="flex items-center space-x-3">
                <Minus className="text-gray-300" />
                <span>Multiples langues</span>
              </li>{" "}
              <li className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <Minus className="text-gray-300" />
                  <span>Tracking</span>
                </div>
                <div className="group relative">
                  <CircleHelp size={18} className="cursor-help text-gray-500" />
                  <div className="hidden group-hover:block absolute right-full md:left-full top-1/2 -translate-y-1/2 ml-2 w-64 bg-white p-3 rounded-lg border z-50">
                    <div className="text-sm text-gray-600">
                      Le tracking est un outil très puissant pour augmenter
                      votre convertion, il permet de comprendre comment les
                      utilisateurs naviguent sur votre site et pourquoi ils le
                      quittent.
                      <br />
                      <br />
                      Exemple, vous êtes:
                      <div className="w-52 max-w-full text-sm">
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Appareil</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getDeviceLogo(device)}
                              alt={device}
                              className="size-4 drop-shadow-sm"
                            />
                            <span className="capitalize">
                              {device === "desktop"
                                ? "ordinateur"
                                : device === "tablet"
                                ? "tablette"
                                : "mobile"}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Navigateur</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getBrowserLogo(browser)}
                              alt={browser}
                              className="size-4 drop-shadow-sm"
                            />
                            <span>{browser}</span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Système</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getOSLogo(os)}
                              alt={os}
                              className="size-4 drop-shadow-sm"
                            />
                            <span>{os}</span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-2">
                          <div className="font-medium">Temps sur la page</div>
                          <div>{formatTime(timeOnSite)}</div>
                        </div>
                        <div className="flex justify-between py-2">
                          <div className="font-medium">Visites</div>
                          <div>{visits}</div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute -right-2 md:-left-2 top-1/2 -translate-y-1/2 w-4 h-4 rotate-45 bg-white border-r border-t md:border-b md:border-l md:border-r-0 md:border-t-0"></div>
                  </div>
                </div>
              </li>
            </ul>

            <hr className="border my-2" />

            <p className="my-4 text-start text-sm">
              Obtenez votre site dès maintenant:
            </p>

            <a
              href={getStripeUrl("https://buy.stripe.com/fZe6q84em7ko8Xm9AF")}
              className=" hover:shadow-lg hover:shadow-blue-500/50 duration-300 font-semibold ease-in-out bg-gradient-to-t from-blue-500 via-blue-500 via-60% to-blue-400 text-white bg-primary-600 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
            >
              740€
            </a>
            <p className="my-2 text-gray-400">ou</p>

            <div className="flex md:flex-row flex-col w-full">
              <a
                href={getStripeUrl("https://buy.stripe.com/5kAdSA5iqcEI4H6fZ4")}
                className="w-full py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-gray-100 rounded-lg hover:bg-blue-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
              >
                49€/mo
              </a>
              <a
                href={getStripeUrl("https://buy.stripe.com/fZeaGo6mudIM1uU4gp")}
                className="w-full py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-gray-100 rounded-lg hover:bg-blue-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
              >
                490€/an
              </a>
            </div>
          </div>

          {/* Premium Card */}
          <div className="font-poppins p-12 flex flex-col max-w-lg text-center text-gray-900 bg-white rounded-3xl border">
            <h3 className="text-start text-xl text-gray-900 mb-4">Premium</h3>
            <ul className="mb-8 space-y-4 text-left">
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>{parseBoldText("Jusqu'à **10 pages**")}</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Design & Développement</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Aller-retours illimités</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Optimisé SEO</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Compatible mobile</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Hébergement sécurisé HTTPS</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Changements illimités</span>
              </li>{" "}
              <li className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <Check className="text-blue-500" />
                  <span>Mode sombre/clair</span>
                </div>
                <div className="group relative">
                  <CircleHelp size={18} className="cursor-help text-gray-500" />
                  <div className="hidden group-hover:block absolute right-full md:left-full top-1/2 -translate-y-1/2 ml-2 w-64 bg-white p-3 rounded-lg border z-50">
                    <div className="text-sm text-gray-600">
                      <img
                        src={darkmode}
                        alt="Illustration du mode sombre et du mode clair"
                        className=""
                      />
                      <br />
                      Le mode sombre/clair permet à vos utilisateurs de choisir
                      l'apparence de votre site selon leurs préférences.
                    </div>
                    <div className="absolute -right-2 md:-left-2 top-1/2 -translate-y-1/2 w-4 h-4 rotate-45 bg-white border-r border-t md:border-b md:border-l md:border-r-0 md:border-t-0"></div>
                  </div>
                </div>
              </li>
              <li className="flex items-center space-x-3">
                <Minus className="text-gray-300" />
                <span>Réservations</span>
              </li>
              <li className="flex items-center space-x-3">
                <Minus className="text-gray-300" />
                <span>Multiples langues</span>
              </li>{" "}
              <li className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <Minus className="text-gray-300" />
                  <span>Tracking</span>
                </div>
                <div className="group relative">
                  <CircleHelp size={18} className="cursor-help text-gray-500" />
                  <div className="hidden group-hover:block absolute right-full md:left-full top-1/2 -translate-y-1/2 ml-2 w-64 bg-white p-3 rounded-lg border z-50">
                    <div className="text-sm text-gray-600">
                      Le tracking est un outil très puissant pour augmenter
                      votre convertion, il permet de comprendre comment les
                      utilisateurs naviguent sur votre site et pourquoi ils le
                      quittent.
                      <br />
                      <br />
                      Exemple, vous êtes:
                      <div className="w-52 max-w-full text-sm">
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Appareil</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getDeviceLogo(device)}
                              alt={device}
                              className="size-4 drop-shadow-sm"
                            />
                            <span className="capitalize">
                              {device === "desktop"
                                ? "ordinateur"
                                : device === "tablet"
                                ? "tablette"
                                : "mobile"}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Navigateur</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getBrowserLogo(browser)}
                              alt={browser}
                              className="size-4 drop-shadow-sm"
                            />
                            <span>{browser}</span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Système</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getOSLogo(os)}
                              alt={os}
                              className="size-4 drop-shadow-sm"
                            />
                            <span>{os}</span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-2">
                          <div className="font-medium">Temps sur la page</div>
                          <div>{formatTime(timeOnSite)}</div>
                        </div>
                        <div className="flex justify-between py-2">
                          <div className="font-medium">Visites</div>
                          <div>{visits}</div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute -right-2 md:-left-2 top-1/2 -translate-y-1/2 w-4 h-4 rotate-45 bg-white border-r border-t md:border-b md:border-l md:border-r-0 md:border-t-0"></div>
                  </div>
                </div>
              </li>
            </ul>

            <hr className="border my-2" />

            <p className="my-4 text-start text-sm">
              Obtenez votre site dès maintenant:
            </p>

            <a
              href={getStripeUrl("https://buy.stripe.com/cN2bKs6mugUY0qQ007")}
              className="hover:shadow-lg hover:shadow-blue-500/50 duration-300 font-semibold ease-in-out bg-gradient-to-t from-blue-500 via-blue-500 via-60% to-blue-400 text-white bg-primary-600 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
            >
              1 190€
            </a>
            <p className="my-2 text-gray-400">ou</p>

            <div className="flex md:flex-row flex-col w-full">
              <a
                href={getStripeUrl("https://buy.stripe.com/14keWEeT0gUY6Pe5ks")}
                className="w-full py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-gray-100 rounded-lg hover:bg-blue-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
              >
                79€/mo
              </a>
              <a
                href={getStripeUrl("https://buy.stripe.com/bIY15O12a8os1uU6oy")}
                className="w-full py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-gray-100 rounded-lg hover:bg-blue-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
              >
                790€/an
              </a>
            </div>
          </div>

          {/* Extra Card */}
          <div className="z-10 font-poppins p-12 flex flex-col max-w-lg text-center text-gray-900 bg-white rounded-3xl border">
            <h3 className="text-start text-xl text-gray-900 mb-4">
              Extra (devis gratuit)
            </h3>
            <ul className="mb-8 space-y-4 text-left">
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>{parseBoldText("**Pages illimitées**")}</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Design & Développement</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Aller-retours illimités</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Optimisé SEO</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Compatible mobile</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Hébergement sécurisé HTTPS</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Changements illimités</span>
              </li>{" "}
              <li className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <Check className="text-blue-500" />
                  <span>Mode sombre/clair</span>
                </div>
                <div className="group relative">
                  <CircleHelp size={18} className="cursor-help text-gray-500" />
                  <div className="hidden group-hover:block absolute right-full top-1/2 -translate-y-1/2 mr-2 w-64 bg-white p-3 rounded-lg border z-50">
                    <div className="text-sm text-gray-600">
                      <img
                        src={darkmode}
                        alt="Illustration du mode sombre et du mode clair"
                        className=""
                      />
                      <br />
                      Le mode sombre/clair permet à vos utilisateurs de choisir
                      l'apparence de votre site selon leurs préférences.
                    </div>
                    <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-4 h-4 rotate-45 bg-white border-r border-t"></div>
                  </div>
                </div>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Réservations</span>
              </li>
              <li className="flex items-center space-x-3">
                <Check className="text-blue-500" />
                <span>Multiples langues</span>
              </li>{" "}
              <li className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <Check className="text-blue-500" />
                  <span>Tracking</span>
                </div>
                <div className="group relative">
                  <CircleHelp size={18} className="cursor-help text-gray-500" />
                  <div className="hidden group-hover:block absolute right-full top-1/2 -translate-y-1/2 mr-2 w-64 bg-white p-3 rounded-lg border z-50">
                    <div className="text-sm text-gray-600">
                      Le tracking est un outil très puissant pour augmenter
                      votre convertion, il permet de comprendre comment les
                      utilisateurs naviguent sur votre site et pourquoi ils le
                      quittent.
                      <br />
                      <br />
                      Exemple, vous êtes:
                      <div className="w-52 max-w-full text-sm">
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Appareil</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getDeviceLogo(device)}
                              alt={device}
                              className="size-4 drop-shadow-sm"
                            />
                            <span className="capitalize">
                              {device === "desktop"
                                ? "ordinateur"
                                : device === "tablet"
                                ? "tablette"
                                : "mobile"}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Navigateur</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getBrowserLogo(browser)}
                              alt={browser}
                              className="size-4 drop-shadow-sm"
                            />
                            <span>{browser}</span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-1.5">
                          <div className="font-medium">Système</div>
                          <div className="flex items-center gap-2">
                            <img
                              src={getOSLogo(os)}
                              alt={os}
                              className="size-4 drop-shadow-sm"
                            />
                            <span>{os}</span>
                          </div>
                        </div>
                        <div className="flex justify-between border-b py-2">
                          <div className="font-medium">Temps sur la page</div>
                          <div>{formatTime(timeOnSite)}</div>
                        </div>
                        <div className="flex justify-between py-2">
                          <div className="font-medium">Visites</div>
                          <div>{visits}</div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-4 h-4 rotate-45 bg-white border-r border-t"></div>
                  </div>
                </div>
              </li>
            </ul>

            <hr className="border my-2" />

            <div className="flex flex-col justify-center items-center h-full w-full">
              <p className="mb-2">Dès 1 690€ ou 129€/mo</p>
              <a
                href="#contact"
                className="w-full hover:shadow-lg hover:shadow-blue-500/50 duration-300 font-semibold ease-in-out bg-gradient-to-t from-blue-500 via-blue-500 via-60% to-blue-400 text-white bg-primary-600 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Nous contacter
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
