import Navbar from "../components/Navbar";
import APropos from "../sections/APropos";
import Contact from "../sections/Contact";
import Footer from "../sections/Footer";
import Gallery from "../sections/Gallery";
import Header from "../sections/Header";
import Portfolio from "../sections/Portfolio";
import Pricing from "../sections/Pricing";
import Steps from "../sections/Steps";
import Testimonial from "../sections/Temoignages";
import Pourquoi from "../sections/Pourquoi";

import React from "react";
import Notification from "../components/Notification";
import ActivePeople from "../components/ActivePeople";
import Bento from "../sections/Bento";
import Manifeste from "../sections/Manifeste";
import Processus from "../sections/Processus";
import axios from "axios";

const Home = () => {
  // Ajout de useEffect pour vérifier le paramètre ref au chargement
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refCode = urlParams.get("ref");

    // Récupère le lien depuis pastebin
    const fetchAffiliationUrl = async () => {
      try {
        const response = await axios.get("https://pastebin.com/raw/QPPv8Ly5");
        const affiliationUrl = response.data.trim();

        if (refCode) {
          await axios.post(affiliationUrl, {
            code: refCode,
          });
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du lien d'affiliation:",
          error
        );
      }
    };

    fetchAffiliationUrl();
  }, []);

  return (
    <>
      <ActivePeople />
      {/* <Notification /> */}

      <div className="2xl:w-2/3 lg:w-5/6 mx-auto selection:bg-indigo-700 selection:text-white semibold">
        <Navbar />

        <Header />
        {/* <Steps /> */}
        <Gallery />
        <Bento />
        <APropos />
        <Pourquoi />
        {/* <Manifeste /> */}
        {/* <Processus /> */}

        <Pricing />
        <Testimonial />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Home;
